$primaryRed: #e30513;
$transparentRed: rgb(227, 5, 19, 0.5);
$darkerRed: #c20411;

$white: #fff;
$darkGrey: darken(#747474, 20%);
$mediumGrey: #e5e5e5;
$lightGrey: #f6f6f6;
$black: #000;



#edv-services {
    background-image: url("./assets/printer-repair.png");
    filter: invert(100);
}

#repair {
    width: 65% !important;
    height: 65% !important;
    background-image: url("./assets/computer-repair.png");
    filter: invert(100);
}

#network-solutions {

    background-image: url("./assets/network.png");
    filter: invert(100);
}

#hardware {
    width: 75% !important;
    height: 75% !important;
    background-image: url("./assets/laptop.png");
    filter: invert(100);
}

#software {
    width: 65% !important;
    height: 65% !important;
    background-image: url("./assets/software.png");
    filter: invert(100);
}

#care-packages {
    width: 75% !important;
    height: 75% !important;
    background-image: url("./assets/box.png");
    filter: invert(100);
}


.services-h1 {
    font-size: 5rem;
    color: $primaryRed;
    text-align: center;
    margin: 1rem;
}

.services-bg {
    background-color: $lightGrey;
    width: 75%;
    height: 22.5rem;

    .services-bg-img {
        position: absolute;
        background-image: url("./assets/fuhrpark.png");
        width: 75%;
        height: 30rem;
        object-fit: cover;
        background-size: cover;
        background-position: center;

    }
}


.services {

    position: absolute;


    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    /* Modify the gap value here */
    padding: 1rem;
    justify-items: center;
    width: 70%;
    margin: 0 auto;


}

.services-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 18rem;
    transition: all 0.3s ease-in-out;


    &:hover {
        transform: scale(1.05);
    }



    .service-img-bg {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $black;
        width: 4rem;
        height: 4rem;
        position: absolute;
        margin-top: 2rem;
        z-index: 1000;
        border-radius: 50%;

        .service-img {
            width: 80%;
            height: 80%;
            object-fit: cover;
            background-size: cover;
            background-position: center;

        }
    }

    .service-card {
        display: flex;
        flex-direction: column;
        width: 22.5rem;
        min-height: 16rem;
        /* Use min-height instead of height */
        background-color: $lightGrey;
        margin-top: 3.5rem;
        border-radius: 2rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);




        .service-text {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            height: 100%;

            gap: 0.5rem;
            padding: 1rem;
            padding-top: 3rem;

            h1 {
                font-size: 2rem;
                color: $primaryRed;
                height: 2.5rem;
            }

            p {
                font-size: 1rem;
                color: $darkGrey;
                line-height: 1.6rem;
                text-align: center;
                height: 6rem;
            }
        }

        .service-line {
            display: none;
            align-self: center;
            justify-self: center;
            width: 75%;
            height: 0.1rem;
            background-color: $darkGrey;
        }

        .service-more {
            align-self: center;
            justify-self: center;
            list-style: none;
            text-decoration: none;
            color: $primaryRed;
            font-size: 1.2rem;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: $darkerRed;
            }
        }
    }
}

@media (max-width: 1024px) {

    /* For laptop */
    .services {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;

        .service {
            &:hover {
                transform: none;
            }
        }
    }

}


@media (max-width: 768px) {

    /* For tablet */
    .services {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;

        .service {
            width: 18rem !important;

            &:hover {
                transform: none;
            }

            padding-bottom: 1.5rem !important;
        }

    }
}

@media (max-width: 480px) {

    /* For phone */

    .services-h1 {
        font-size: 3rem;
    }

    .services {
        grid-template-columns: 1fr;
        width: 100%;

        .service {
            &:hover {
                transform: none;
            }
        }
    }

}


@media (min-width: 1024px) and (max-width: 1400px) {
    .services {
        width: 100% !important;

        .service {
            &:hover {
                transform: none;
            }
        }
    }
}


@media (max-height: 375px) {
    .services {
        grid-template-columns: 1fr;
        width: 100%;

        .service {
            &:hover {
                transform: none;
            }
        }
    }
}