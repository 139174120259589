@keyframes slide {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(-100%, 0, 0);
    }
}


.logo-scroller {
    overflow: hidden;
    padding: 3rem 0;
    white-space: nowrap;




    .logos {
        will-change: transform;
        animation: 20s slide infinite linear;
        display: inline-block;



        img {
            height: 5.5rem;
            margin: 0 2rem;
        }
    }
}