$primaryRed: #e30513;
$white: #fff;
$darkGrey: darken(#747474, 20%);
$mediumGrey: #e5e5e5;
$lightGrey: #f6f6f6;
$black: #000;


@font-face {
    font-family: 'Coolvetica';
    src: url("./assets/fonts/coolvetica.otf");
}

* {
    font-family: "Coolvetica";
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;


}

.landing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    background-color: white;
}



.mainbg {
    background-image: url('./assets/platzhirsch.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 45rem;
    width: 45vw;

}

.landing {
    width: 45vw;
    height: 50rem;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .landing-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        align-content: center;
        color: $black;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;

        .landing-logo {
            background-image: url("./assets/logo.png");
            background-size: contain;
            background-repeat: no-repeat;
            width: 25rem;
            height: 10rem;
            align-self: center;

            @media (max-width: 768px) {
                width: 20rem !important;
                height: 8rem !important;
            }

        }

        p {
            font-size: 1.5rem;
            font-weight: 400;
            color: $darkGrey;
        }
    }

    .support,
    .webshop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 35rem;
        height: 8rem;
        margin-top: 2rem;
        background-color: $primaryRed;
        border-radius: 1.5rem;
        padding: 1rem 2rem;

        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease-in-out;
        cursor: pointer;


        &:hover {
            transform: scale(1.05);
        }

        h2 {
            color: $white;
            font-size: 2.5rem;
            font-weight: 500;
        }

        .support-img {
            background-image: url("./assets/pc-search.png");
            background-size: contain;
            background-repeat: no-repeat;

            height: 6rem;
            width: 6rem;

        }

        .webshop-img {
            background-image: url("./assets/shopping-bag.png");
            background-size: contain;
            background-repeat: no-repeat;

            height: 6rem;
            width: 6rem;
        }

    }
}


@media (max-width: 1024px) {
    .mainbg {
        display: none;
    }

}

@media (min-width: 375px) and (max-width: 768px) {
    .landing {
        width: 90vw;
        height: auto;

        .landing-logo {
            display: none;
        }

        .landing-text {
            font-size: 1.4rem;
            margin-bottom: 0.5rem;
        }

        .support,
        .webshop {
            width: 90%;
            height: auto;
            padding: 0.5rem 1rem;
        }
    }
}




@media (max-width: 768px) {
    .landing-container {
        height: auto;
        /* Adjusted */
        padding: 2rem 1rem;
        /* Added for mobile padding */
    }

    .landing {
        width: 90vw;
        /* Increased width */
        height: auto;
        /* Adjusted */
        padding: 1rem;
        /* Added padding */

        .landing-logo {
            width: 16rem !important;
            /* Reduced size further */
            height: 6rem !important;
            /* Reduced size further */
        }

        .landing-text {
            font-size: 1.4rem;
            margin-bottom: 0.5rem;
            /* Adjusted spacing */
        }

        .support,
        .webshop {
            width: 90%;
            /* Adjusted for more responsiveness */
            height: auto;
            /* Removed fixed height */
            padding: 0.5rem 1rem;
            /* Adjusted padding */
        }
    }
}


@media (max-width: 1400px) {

    .support,
    .webshop {
        justify-content: center !important;
        width: 80%;
        height: 15%;

        h2 {
            text-align: center;
        }

        .support-img,
        .webshop-img {
            display: none;
        }
    }
}