$primaryRed: #e30513;
$transparentRed: rgb(227, 5, 19, 0.5);
$darkerRed: #c20411;

$white: #fff;
$darkGrey: darken(#747474, 20%);
$mediumGrey: #e5e5e5;
$lightGrey: #f6f6f6;
$black: #000;

$baseFontSize: 1rem; // Set the base font size

.footer {
    width: 100%;
    background-color: $darkGrey;

    padding: 2rem 0; // Use rem units for padding
    color: $white;
    font-size: .8rem; // Increase the font size
    font-weight: 100;
    gap: 2rem; // Use rem units for gap

    /* Add this line to change the spacing between letters */
    letter-spacing: 0.1rem;

    .footer-top {
        display: flex;
        justify-content: center;
        gap: 1rem;
        letter-spacing: 0.2rem;

        h1 {
            text-decoration: none;
            color: $white;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: $primaryRed;
            }
        }
    }

    .footer-bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        letter-spacing: 0.2rem;
    }
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it is above all other content */
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.modal-content h2 {
    margin-bottom: 2rem;
    color: #000;
}

.modal-content button {
    margin: .2rem 1rem;
    padding: 1rem 2rem;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.modal-content button:first-of-type {
    background-color: #4CAF50;
    color: white;
    font-size: 1.5rem;
}

.modal-content button:last-of-type {
    background-color: #f44336;
    color: white;
    font-size: 1.5rem;

}

@media screen and (max-width: 768px) {
    .footer {
        font-size: 1rem;
    }

    .footer-top {
        flex-direction: column;
        gap: 1rem;
        width: 80%;
        margin-left: 1rem;
    }

    .footer-bottom {
        margin-top: 1rem;

        .designed-by {
            margin-bottom: 1rem;
            font-size: .9rem;
            text-align: center;
        }
    }
}