$primaryRed: #e30513;
$white: #fff;
$darkGrey: darken(#747474, 20%);
$mediumGrey: #e5e5e5;
$lightGrey: #f6f6f6;
$black: #000;


header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 6.5rem;
    padding: 0 2rem;
    background-color: $white;
    color: $black;

    width: 100%;
    max-width: 100%;

    nav {
        padding: 1rem;
    }

    img {
        width: 14rem;
        height: 5rem;
    }

    .nav-btn {
        padding: 5px;
        cursor: pointer;
        background: transparent;
        border: none;
        outline: none;
        color: $black;
        display: none;
        opacity: 0;
        font-size: 1.8rem;
    }


    nav {
        a {
            margin: 0 1rem;
            color: $black;
            text-decoration: none;
            font-size: 1.5rem;
            transition: 0.3s;

            &:hover {
                color: $primaryRed;
            }
        }
    }
}







.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1024px) {


    header {
        img {
            width: 12rem;
            height: 4rem;
        }
    }

    header .nav-btn {
        display: block;
        opacity: 1;
    }

    header nav {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: $lightGrey;
        transition: 1s;
        height: 100%;
    }

    header .responsive_nav {
        transform: translateY(100vh);
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    nav a {
        font-size: 1.5rem;
    }
}


@media (max-height: 450px) {
    header {
        height: 5rem !important;
    }
}