/* Change Carousel Indicators to #E30513 */

.carousel-indicators {
    margin-top: 10px;
}

.carousel-indicators [data-bs-target] {
    background-color: #e30513 !important;
}

/* Change Active Indicator */
.carousel-indicators .active {
    background-color: #b30410 !important;
    /* Slightly darker for active */
    opacity: 1;
}

/* Change Arrow Button Colors */
.carousel-control-prev-icon,
.carousel-control-next-icon {
    filter: invert(100%);
    /* Converts to #E30513 */
}

/* Increase Arrow Size for Better Visibility */
.carousel-control-prev,
.carousel-control-next {
    width: 5%;
}