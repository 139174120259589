/**
    GENERAL STYLES
*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.reveal {
    position: relative;
    transform: translateY(0);
    opacity: 1;
    transition: all .5s ease-in-out;
}

.active {
    opacity: 1;
    transform: translateY(0);
}