$primaryRed: #e30513;
$transparentRed: rgb(227, 5, 19, 0.5);
$white: #fff;
$darkGrey: darken(#747474, 20%);
$mediumGrey: #e5e5e5;
$lightGrey: #f6f6f6;
$black: #000;

.testimonials-h1 {
    font-size: 5rem;
    color: $primaryRed;
    text-align: center;
    margin: 1rem;
}

.testimonials-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    padding: 2rem;


    .testimonials-text {
        text-align: center;
        gap: 2rem;
        width: 20vw;


        h1 {
            font-size: 3.5rem;
            color: $primaryRed;
        }

        p {
            font-size: 1.2rem;
            color: $darkGrey;
            text-align: center;
            line-height: 1.6rem;
            margin-top: 1rem;
        }
    }

    .testimonials {
        width: 40vw;
        height: 80vh;
        overflow-y: scroll;
        scroll-behavior: smooth;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;
        flex-direction: column;
        border: .8rem solid $primaryRed;
        padding: 2rem;
        border-radius: 1rem;

        &::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }
    }

    .testimonial {

        .testimonial-icon {
            background-image: url('./assets/quote.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 4rem;
            height: 4rem;
            margin-bottom: .5rem;
            filter: invert(100%);
        }

        .testimonial-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            p {
                font-family: "AntonSC-Regular", sans-serif;
                font-size: 1.5rem;
                color: $black;
                text-align: center;
                font-weight: lighter;
                line-height: 3rem;
            }
        }

        .testimonial-author {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            margin-top: 1rem;

            p {
                font-family: "AntonSC-Regular", sans-serif;
                font-weight: bolder;
                font-size: 1.2rem;
                color: $black;
            }
        }

        .testimonial-line {
            width: 100%;
            height: 2px;
            border-radius: 1rem;
            background-color: $black;
            margin-top: 2rem;
        }
    }


}

.testimonial-mobile-next {
    display: none;
}

@media (min-width: 375px) and (max-width: 768px) {
    .testimonials-h1 {
        font-size: 2.5rem !important;
    }

    .testimonials-text {
        width: 90% !important;

        h1 {
            font-size: 2rem !important;

        }
    }

    .testimonials {
        width: 100% !important;
        height: 80vh !important;
        padding: 1rem;
    }

    .testimonial {
        width: 100%;

        .testimonial-text {
            p {
                font-size: 1rem !important;
                line-height: 1.8rem !important;
            }
        }
    }
}

// Media queries for responsive design
@media (max-width: 768px) {
    .testimonials-container {
        flex-direction: column;
        padding: 1rem;
    }

    .testimonials-h1 {
        font-size: 3rem;
    }

    .testimonials-text {
        width: 90% !important;

        h1 {
            font-size: 2rem !important;

        }
    }

    .testimonials {
        width: 100% !important;
        height: 80vh !important;
        padding: 1rem;
    }

    .testimonial {
        width: 100%;

        .testimonial-text {
            p {
                font-size: 1rem !important;
                line-height: 1.8rem !important;
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1400px) {
    .testimonials-text {
        width: 35vw !important
    }

    .testimonials {
        width: 50vw !important;
    }

    .testimonial-text {
        p {
            line-height: 1.8rem !important;
        }
    }
}

// PUGGGGGGGGGGGHGHFDHSGDFSAFGJAFDSKGJASGDKSAJGF
@media (max-height: 450px) {
    .testimonials-container {
        flex-direction: column;
        padding: 1rem;
    }

    .testimonials-h1 {
        font-size: 3rem;
    }

    .testimonials-text {
        width: 90% !important;

        h1 {
            font-size: 2rem !important;

        }
    }

    .testimonials {
        width: 100% !important;
        height: 80vh !important;
        padding: 1rem;
    }

    .testimonial {
        width: 100%;

        .testimonial-text {
            p {
                font-size: 1rem !important;
                line-height: 1.8rem !important;
            }
        }
    }
}